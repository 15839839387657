import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Content, Image, Text } from 'components/Quote/_quote'

const Quote2 = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          quote {
            id
            quote2 {
              text
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const { quote2 } = datas.markdownRemark.frontmatter.quote

  return (
    <Container>
      <Image />
      <Content>
        <Text>{quote2.text}</Text>
      </Content>
    </Container>
  )
}

export default Quote2
