import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'

import { maxScreen } from 'styles/_breakpoints'

import Social from 'components/Social/Social'

const FlexBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 97.5rem;
  height: 97.5rem;
  object-fit: cover;
  border-radius: 2rem;

  @media ${maxScreen.tablet} {
    width: 100%;
    height: 60rem;
    border-radius: 1rem;
  }
`

const BoxGreen = styled.div`
  position: absolute;
  right: 0;
  z-index: 1;
  width: 60rem;
  height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.opgreen};
  border-radius: 1rem;

  @media ${maxScreen.tablet} {
    width: 100%;
  }
`

const Content = styled.div`
  position: relative;
  width: 50rem;
  height: fit-content;
  padding: 0 5rem;
  color: ${({ theme: { colors } }) => colors.black};

  @media ${maxScreen.tablet} {
    padding: 0 4rem;
  }
`

const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 3rem;
`

const Title = styled.h2`
  display: flex;
  font-weight: bold;
  text-indent: 0.5rem;
`

const TitleShadow = styled.p`
  position: absolute;
  top: 0;
  left: 4.25rem;
  z-index: -1;
  line-height: 1;
  text-transform: uppercase;
  font-size: clamp(3.5rem, 5vw, 5rem);
  font-weight: bold;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  color: ${({ theme: { colors } }) => colors.opgray};

  @media ${maxScreen.tablet} {
    left: 3.5rem;
  }
`

const Span = styled.span`
  margin-left: 1rem;
  color: ${({ theme: { colors } }) => colors.darkgreen};
`

const Description = styled.div`
  margin-bottom: 5rem;
  font-size: 1.5rem;
  line-height: 2.5rem;

  & > p {
    margin-bottom: 3rem;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        about: {
          title: string
          description: string
          images: {
            src: any
            alt: string
          }
        }
      }
    }
  }
}

const AboutCard = ({ datas }: Datas) => {
  const { title, description, images } = datas.markdownRemark.frontmatter.about

  const titlesplit = title.split(' ')

  return (
    <FlexBox>
      <Image image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
      <BoxGreen>
        <Content>
          <TitleBox>
            <Title>
              {titlesplit[0]}
              <Span>{titlesplit[1]}</Span>
            </Title>
            <TitleShadow>{title}</TitleShadow>
          </TitleBox>
          <Description>
            <ReactMarkdown>{description}</ReactMarkdown>
          </Description>
          <Social direction='row' filters={true} />
        </Content>
      </BoxGreen>
    </FlexBox>
  )
}

export default AboutCard
