import styled from 'styled-components'

import { maxScreen } from 'styles/_breakpoints'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
`

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(28%, 1fr));
  grid-auto-rows: auto;
  gap: 5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
`

export const CardBody = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
`

export const CardTitle = styled.h3`
  margin-bottom: 2rem;
  font-weight: normal;
`

export const CardSubTitle = styled.p`
  margin-bottom: 0.25rem;
  font-size: 1.8rem;
`

export const CardDescription = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  color: ${({ theme: { colors } }) => colors.darkgray};
`

export const CardBox = styled.div`
  display: flex;
  align-items: center;
`

export const CardPrice = styled.p`
  margin: 0 0 0 1rem;
  font-size: 1.6rem;
`

export const CardLine = styled.div`
  width: 90%;
  height: 0.1rem;
  border: none;
  border-top: 0.1rem dotted ${({ theme: { colors } }) => colors.black};
`
