import styled from 'styled-components'

import { Props } from 'types/types'

import { maxScreen, deviceScreen } from 'styles/_breakpoints'

export const TitleBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5rem;
`

export const Title = styled.h2`
  display: flex;
  font-weight: bold;
  text-indent: 0.5rem;
  margin-left: 1.5rem;

  @media ${maxScreen.desktop} {
    margin-left: 0;
  }
`

export const TitleShadow = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  line-height: 1;
  text-transform: uppercase;
  font-size: clamp(3.5rem, 5vw, 5rem);
  font-weight: bold;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  color: ${({ theme: { colors } }) => colors.opgray};

  @media ${maxScreen.tablet} {
    left: 1.5rem;
  }
`

export const Span = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme: { colors } }) => colors.darkgreen};
`

export const Form = styled.form`
  width: 100%;
  margin-top: 5rem;
`

export const FormBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  select,
  input {
    cursor: pointer;
  }

  @media ${maxScreen.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${deviceScreen.land} {
    flex-direction: column;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
`

export const FormLabel = styled.label`
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.black};
`

export const FormInput = styled.input<Props>`
  font-size: 1.4rem;
  font-family: inherit;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  background: ${({ theme: { colors } }) => colors.opgreen};
  color: ${({ theme: { colors } }) => colors.black};

  &:focus {
    outline: none;
  }
`

export const FormSelect = styled.select<Props>`
  font-size: 1.4rem;
  font-family: ${({ theme: { fonts } }) => fonts.ptserif};
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  background: ${({ theme: { colors } }) => colors.opgreen};
  color: ${({ theme: { colors } }) => colors.opblack};

  &:focus {
    outline: none;
  }
`

export const FormOption = styled.option<Props>`
  font-size: 1.4rem;
  font-family: ${({ theme: { fonts } }) => fonts.ptserif};
  border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  background: ${({ theme: { colors } }) => colors.opgreen};
  color: ${({ theme: { colors } }) => colors.opblack};

  &:hover {
    background: ${({ theme: { colors } }) => colors.red};
  }
`

export const FormMessage = styled.textarea<Props>`
  height: 15rem;
  resize: vertical;
  font-size: 1.4rem;
  font-family: ${({ theme: { fonts } }) => fonts.ptserif};
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  background: ${({ theme: { colors } }) => colors.opgreen};
  color: ${({ theme: { colors } }) => colors.black};

  &:focus {
    outline: none;
  }
`

export const Message = styled.p`
  margin-top: 0.75rem;
  color: ${({ theme: { colors } }) => colors.red};
`

export const FormButton = styled.button`
  margin-top: 7rem;
  padding: 1rem 1.8rem;
  border-radius: 0.25rem;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  border: 0.1rem solid ${({ theme: { colors } }) => colors.darkgreen};
  background: ${({ theme: { colors } }) => colors.darkgreen};
  color: ${({ theme: { colors } }) => colors.black};
  cursor: pointer;

  @media ${maxScreen.desktop} {
    padding: 1.25rem 2rem;
  }
`

export const CheckBox = styled.section`
  position: relative;
  width: fit-content;
  margin-bottom: 0;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

export const CheckBoxSpan = styled.span`
  display: block;
`

export const CheckBoxInput = styled.input<Props>`
  width: fit-content;
  height: 25px;
  width: 25px;
  margin-right: 1rem;
  appearance: none;
  border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  background: ${({ theme: { colors } }) => colors.opgreen};
  color: ${({ theme: { colors } }) => colors.opblack};
  border-radius: 0.25rem;
  outline: none;
  transition-duration: 0.3s;
  cursor: pointer;

  &:checked {
    background: ${({ theme: { colors } }) => colors.opgreen};
    border: 0.1rem solid ${({ theme: { colors } }) => colors.opgreen};
  }

  &:active {
    border: 2px solid ${({ theme: { colors } }) => colors.opgreen};
  }
`

export const CheckBoxLabel = styled.label`
  display: flex;
  margin-bottom: 0;
  font-size: 1.6rem;
  cursor: pointer;

  & > ${CheckBoxInput}:checked + ${CheckBoxSpan}::before {
    content: '✔';
    color: ${({ theme: { colors } }) => colors.darkgreen};
    position: absolute;
    left: 0.6rem;
    top: 0;
    font-size: 1.6rem;
  }
`
