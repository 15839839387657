import React from 'react'
import styled from 'styled-components'

import { deviceScreen, maxScreen } from 'styles/_breakpoints'

import Button from 'components/Button/Button'

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  width: 67rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${maxScreen.smart} {
    width: 49rem;
  }

  @media ${deviceScreen.smland} {
    width: 42rem;
    margin-top: 7rem;
  }

  @media ${deviceScreen.tablet} {
    width: 49rem;
  }

  @media ${deviceScreen.desktop} {
    width: 63rem;
  }
`

const Title = styled.h1`
  margin-bottom: 2rem;
  line-height: 1;

  @media ${deviceScreen.smland} {
    margin-bottom: 1.5rem;
  }
`

const Description = styled.p`
  margin-bottom: 5rem;
  font-size: clamp(1.3rem, 2vw, 1.8rem);
  line-height: 2.75rem;
  color: ${({ theme: { colors } }) => colors.white};

  @media ${maxScreen.smart} {
    margin-bottom: 4.5rem;
    line-height: 2.25rem;
  }

  @media ${deviceScreen.smland} {
    margin-bottom: 3rem;
    line-height: 2rem;
  }

  @media ${deviceScreen.tablet} {
    margin-bottom: 5rem;
    line-height: 2.5rem;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        hero: {
          title: string
          description: string
          linkTo: string
          linkText: string
        }
      }
    }
  }
}

const HeroBG = ({ datas }: Datas) => {
  const { title, description, linkTo, linkText } = datas.markdownRemark.frontmatter.hero

  return (
    <Container>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Button to={linkTo}>{linkText}</Button>
      </Content>
    </Container>
  )
}

export default HeroBG
