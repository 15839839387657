import React from 'react'

import { Container, GridBox, Card, CardTitle } from 'components/Menus/_menucard'

import MenusItems from 'components/Menus/MenuItems'

interface Datas {
  datas: {
    allMarkdownRemark: {
      edges: Edges[]
    }
  }
}

interface Edges {
  node: {
    frontmatter: {
      id: number
      title: string
      menu: Menu[]
    }
  }
}

interface Menu {
  id: number
  title: string
  description: string
  price: string
}

const MenusCards = ({ datas }: Datas) => {
  const menuDatas = datas.allMarkdownRemark.edges

  const breakfast = menuDatas[0].node.frontmatter
  const lunch = menuDatas[1].node.frontmatter
  const dinner = menuDatas[2].node.frontmatter

  return (
    <Container>
      <GridBox>
        <Card>
          <CardTitle>{breakfast.title}</CardTitle>
          {breakfast.menu.map(({ id, title, description, price }: Menu) => (
            <MenusItems id={id} title={title} description={description} price={price} />
          ))}
        </Card>
        <Card>
          <CardTitle>{lunch.title}</CardTitle>
          {lunch.menu.map(({ id, title, description, price }: Menu) => (
            <MenusItems id={id} title={title} description={description} price={price} />
          ))}
        </Card>
        <Card>
          <CardTitle>{dinner.title}</CardTitle>
          {dinner.menu.map(({ id, title, description, price }: Menu) => (
            <MenusItems id={id} title={title} description={description} price={price} />
          ))}
        </Card>
      </GridBox>
    </Container>
  )
}

export default MenusCards
