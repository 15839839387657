import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import isEmail from 'validator/lib/isEmail'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Info from 'components/Info/Info'

import { maxScreen } from 'styles/_breakpoints'
import {
  TitleBox,
  Title,
  TitleShadow,
  Span,
  Message,
  Form,
  FormGroup,
  FormLabel,
  FormInput,
  FormSelect,
  FormOption,
  FormMessage,
  FormButton,
  FormBox,
  CheckBox,
  CheckBoxInput,
  CheckBoxLabel,
  CheckBoxSpan,
} from './_contactform'

const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
`

interface ContactProps {
  name: string
  email: string
  phone: number
  time: string
  date: string
  guest: number
  message: string
  checkbox: boolean
}

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        contact: {
          title: string
        }
      }
    }
  }
}

const ContactForm = ({ datas }: Datas) => {
  const { title } = datas.markdownRemark.frontmatter.contact
  const titlesplit = title.split(' ')
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm<ContactProps>({
    mode: 'onChange',
  })
  const [values, setValues] = useState({
    name: '',
    email: '',
    phone: '',
    time: '',
    date: '',
    guest: '',
    message: '',
    checkbox: false,
  })

  const { name, email, phone, time, date, guest, message, checkbox } = values

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setValues({ ...values, [e.target.name]: e.target.value, checkbox: true })
  }

  const onSubmit = handleSubmit((data, e: any) => {
    e.preventDefault()

    const form = e.target

    axios({
      method: 'post',
      url: 'https://formspree.io/f/mwkwvdvr',
      data: new FormData(form),
    })
      .then((response) => {
        if (checkbox) e.target.reset()

        toast.dark(`Thanks for reaching out. I'll get back to you soon.`)
        setValues({
          ...values,
          name: '',
          email: '',
          phone: '',
          time: '',
          date: '',
          guest: '',
          message: '',
          checkbox: false,
        })
      })
      .catch((error) => {
        console.log(error)
        toast.dark('Oops, something went wrong. The form could not be submitted.')
        setValues({ ...values, checkbox: false })
      })
  })

  return (
    <Container>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <TitleBox>
        <Title>
          {titlesplit[0]}
          <Span>{titlesplit[1]}</Span>
        </Title>
        <TitleShadow>{title}</TitleShadow>
      </TitleBox>

      <Info direction='column' color={true} />

      <Form name='contact' onSubmit={onSubmit}>
        <FormGroup>
          <FormLabel htmlFor='name' className='form-label'>
            Name
          </FormLabel>
          <FormInput
            {...register('name', {
              required: true,
              minLength: 3,
              maxLength: 20,
              pattern: /^[A-Za-z]+$/i,
            })}
            id='name'
            type='text'
            name='name'
            placeholder='Name'
            value={name}
            onChange={handleChange}
          />
          {errors.name && <Message>Please enter a valid name.</Message>}
        </FormGroup>
        <FormGroup>
          <FormLabel htmlFor='email' className='form-label'>
            Email
          </FormLabel>
          <FormInput
            {...register('email', {
              required: true,
              validate: (input) => isEmail(input),
              minLength: 6,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i,
            })}
            id='email'
            type='email'
            name='email'
            placeholder='Email'
            value={email}
            onChange={handleChange}
          />
          {errors.email && <Message>Please enter a valid email.</Message>}
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor='phone' className='form-label'>
            Phone (optional)
          </FormLabel>
          <FormInput
            {...register('phone', {
              required: false,
              minLength: 10,
            })}
            id='phone'
            type='tel'
            placeholder='Phone(10)'
            name='phone'
            value={phone}
            onChange={handleChange}
          />
          {errors.phone && <Message>Please enter a valid phone number.</Message>}
        </FormGroup>

        <FormBox>
          <FormGroup>
            <FormLabel htmlFor='date' className='form-label'>
              Date
            </FormLabel>
            <FormInput
              {...register('date', {
                required: true,
              })}
              id='date'
              type='date'
              name='date'
              value={date}
              onChange={handleChange}
            />
            {errors.date && <Message>Please select date.</Message>}
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor='time' className='form-label'>
              Time
            </FormLabel>
            <FormSelect
              {...register('time', {
                required: true,
              })}
              id='time'
              name='time'
              value={time}
              onChange={handleChange}
            >
              <FormOption disabled value=''>
                -- select a time --
              </FormOption>
              <FormOption>09:00AM - 10:00AM</FormOption>
              <FormOption>10:00AM - 11:00AM</FormOption>
              <FormOption>11:00AM - 12:00PM</FormOption>
              <FormOption>12:00PM - 13:00PM</FormOption>
              <FormOption>13:00PM - 14:00PM</FormOption>
              <FormOption>14:00PM - 15:00PM</FormOption>
              <FormOption>15:00PM - 16:00PM</FormOption>
              <FormOption>16:00PM - 17:00PM</FormOption>
              <FormOption>17:00PM - 18:00PM</FormOption>
            </FormSelect>
            {errors.time && <Message>Please select time.</Message>}
          </FormGroup>

          <FormGroup>
            <FormLabel htmlFor='guest' className='form-label'>
              Guest
            </FormLabel>
            <FormSelect
              {...register('guest', {
                required: true,
              })}
              id='guest'
              name='guest'
              value={guest}
              onChange={handleChange}
            >
              <FormOption disabled value=''>
                -- select a guest --
              </FormOption>
              <FormOption>2</FormOption>
              <FormOption>3</FormOption>
              <FormOption>4</FormOption>
              <FormOption>5</FormOption>
              <FormOption>6</FormOption>
              <FormOption>7</FormOption>
            </FormSelect>
            {errors.guest && <Message>Please select guest.</Message>}
          </FormGroup>
        </FormBox>

        <FormGroup>
          <FormLabel htmlFor='message' className='form-label'>
            Message
          </FormLabel>
          <FormMessage
            {...register('message', {
              required: true,
              maxLength: 300,
            })}
            id='message'
            name='message'
            placeholder='Message'
            value={message}
            onChange={handleChange}
          ></FormMessage>
          {errors.message && <Message>Please enter a message.</Message>}
        </FormGroup>

        <CheckBox>
          <CheckBoxLabel htmlFor='checkbox'>
            <CheckBoxInput
              {...register('checkbox', { required: true })}
              type='checkbox'
              name='checkbox'
              id='checkbox'
            />
            I'm a human.
            <CheckBoxSpan onChange={handleChange}></CheckBoxSpan>
          </CheckBoxLabel>

          {errors.checkbox && <Message>Please check the checkbox.</Message>}
        </CheckBox>

        <FormButton type='submit' disabled={formState.isSubmitting}>
          Send
        </FormButton>
      </Form>
    </Container>
  )
}

export default ContactForm
