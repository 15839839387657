import React from 'react'

import {
  CardBody,
  CardSubTitle,
  CardDescription,
  CardBox,
  CardLine,
  CardPrice,
} from 'components/Menus/_menucard'

interface Menu {
  id: number
  title: string
  description: string
  price: string
}

const MenusItems = ({ id, title, description, price }: Menu) => {
  return (
    <CardBody key={id}>
      <CardSubTitle>{title}</CardSubTitle>
      <CardDescription>{description}</CardDescription>
      <CardBox>
        <CardLine />
        <CardPrice>{price}</CardPrice>
      </CardBox>
    </CardBody>
  )
}

export default MenusItems
