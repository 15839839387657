import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen } from 'styles/_breakpoints'

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
  grid-auto-rows: auto;
  gap: 3rem;

  @media ${maxScreen.smland} {
    grid-template-columns: 1fr;
  }

  @media ${deviceScreen.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 45rem;
  border-radius: 2.5rem;
  transition: all 0.5s ease-in-out !important;

  @media ${maxScreen.desktop} {
    height: 30rem;
  }

  @media ${deviceScreen.land} {
    height: 35rem;
  }
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        gallery: {
          galleries: Galleries[]
        }
      }
    }
  }
}

interface Galleries {
  id: number
  title: string
  images: {
    src: any
    alt: string
  }
}

const GalleryCard = ({ datas }: Datas) => {
  const { galleries } = datas.markdownRemark.frontmatter.gallery

  const imageData = galleries.map(({ id, images }) => (
    <Image key={id} image={images.src.childImageSharp.gatsbyImageData} alt={images.alt} />
  ))

  return <GridBox>{imageData}</GridBox>
}

export default GalleryCard
