import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Wrapper } from 'styles/_mixins'
import { maxScreen } from 'styles/_breakpoints'

import Map from 'components/Map/Map'
import ContactForm from 'components/Contact/ContactForm'

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 15rem 0 20rem;
`

const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  grid-auto-rows: auto;
  gap: 5rem;

  @media ${maxScreen.tablet} {
    grid-template-columns: 1fr;
  }
`

const Contact = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          contact {
            id
            slug
            title
            images {
              src {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])
                }
              }
              alt
            }
          }
        }
      }
    }
  `)

  const { slug } = datas.markdownRemark.frontmatter.contact

  return (
    <Container id={slug}>
      <Wrapper>
        <GridBox>
          <Map datas={datas} />
          <ContactForm datas={datas} />
        </GridBox>
      </Wrapper>
    </Container>
  )
}

export default Contact
