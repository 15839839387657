import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import { Wrapper } from 'styles/_mixins'
import 'react-h5-audio-player/lib/styles.css'

import GalleryCard from 'components/Gallery/GalleryCard'

const Container = styled.section`
  width: 100%;
  height: fit-content;
  padding: 15rem 0 5rem;
`

const TitleBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 7rem -0.5rem;
`

const Title = styled.h2`
  display: flex;
  font-weight: bold;
`

const TitleShadow = styled.p`
  position: absolute;
  top: 0;
  z-index: -1;
  line-height: 1;
  text-transform: uppercase;
  font-size: clamp(3.5rem, 5vw, 5rem);
  font-weight: bold;
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  color: ${({ theme: { colors } }) => colors.opgray};
`

const Span = styled.span`
  margin-left: 0.65rem;
  color: ${({ theme: { colors } }) => colors.darkgreen};
`

const Gallery = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          gallery {
            id
            slug
            title
            galleries {
              id
              title
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const { slug, title } = datas.markdownRemark.frontmatter.gallery

  const titlesplit = title.split(' ')

  return (
    <Container id={slug}>
      <Wrapper>
        <TitleBox>
          <Title>
            {titlesplit[0]}
            <Span>{titlesplit[1]}</Span>
          </Title>
          <TitleShadow>{title}</TitleShadow>
        </TitleBox>
        <GalleryCard datas={datas} />
      </Wrapper>
    </Container>
  )
}

export default Gallery
