import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Content, Image, Text } from 'components/Quote/_quote'

const Quote = () => {
  const datas = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
        frontmatter {
          quote {
            id
            quote1 {
              text
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  `)

  const { quote1 } = datas.markdownRemark.frontmatter.quote

  return (
    <Container>
      <Image />
      <Content>
        <Text width='true' fontsize='true'>
          {quote1.text}
        </Text>
      </Content>
    </Container>
  )
}

export default Quote
