import styled from 'styled-components'

import { Props } from 'types/types'

import { maxScreen } from 'styles/_breakpoints'

export const Container = styled.section`
  position: relative;
  width: 100%;
  height: fit-content;
  margin: 15rem 0 5rem;
`

export const Image = styled.div`
  width: 100%;
  height: 50rem;
  background: ${({ theme: { backgrounds } }) => backgrounds.quote2};
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 50rem;
    background: ${({ theme: { colors } }) => colors.opblack};

    @media ${maxScreen.smland} {
      height: 40rem;
    }
  }

  @media ${maxScreen.smland} {
    height: 40rem;
  }
`

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const Text = styled.p<Props>`
  width: ${(props) => (props.width ? '80rem' : '65rem')};
  margin-bottom: 0;
  line-height: 1;
  font-size: ${(props) =>
    props.fontsize ? 'clamp(3rem, 7vw, 7rem)' : 'clamp(3rem, 8vw, 7rem)'};
  font-family: ${({ theme: { fonts } }) => fonts.montserrat};
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.white};

  @media ${maxScreen.smland} {
    width: 95%;
  }
`
