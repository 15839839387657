import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image'

import { deviceScreen, maxScreen } from 'styles/_breakpoints'

const Container = styled.section`
  width: 100%;
  height: 98rem;

  @media ${maxScreen.tablet} {
    height: 35rem;
  }

  @media ${deviceScreen.desktop} {
    height: 99rem;
  }

  @media ${deviceScreen.land} {
    height: 118rem;
  }
`

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  width: 100%;
  height: 100%;
  border-radius: 1rem;
`

interface Datas {
  datas: {
    markdownRemark: {
      frontmatter: {
        contact: {
          images: {
            src: any
            alt: string
          }
        }
      }
    }
  }
}

const Map = ({ datas }: Datas) => {
  const { images } = datas.markdownRemark.frontmatter.contact

  return (
    <Container>
      <Image
        image={images.src.childImageSharp.gatsbyImageData}
        alt={images.alt}
        objectFit='cover'
      />
    </Container>
  )
}

export default Map
