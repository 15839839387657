import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { maxScreen } from 'styles/_breakpoints'

const Link = styled(AnchorLink)`
  width: fit-content;
  padding: 1.25rem 1.8rem;
  border-radius: 0.25rem;
  border: none;
  font-size: clamp(1.4rem, 2vw, 1.6rem);
  font-weight: normal;
  line-height: 1;
  background: ${({ theme: { colors } }) => colors.darkgreen};
  color: ${({ theme: { colors } }) => colors.black};
  cursor: pointer;

  @media ${maxScreen.desktop} {
    padding: 1rem 1.6rem;
  }
`

interface Link {
  to: string
  children: any
}

const Button = ({ to, children }: Link) => {
  return (
    <Link to={to} stripHash>
      {children}
    </Link>
  )
}

export default Button
