import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { AnimationFade } from 'libs/animation'

import { Wrapper } from 'styles/_mixins'
import { deviceScreen, maxScreen } from 'styles/_breakpoints'

import HeroBG from 'components/Hero/HeroBG'
import HeroContent from 'components/Hero/HeroContent'
import Info from 'components/Info/Info'
import Social from 'components/Social/Social'

const Container = styled.section`
  width: 100%;
  height: 100vh;

  & ${Wrapper} {
    position: relative;
    height: 100%;
  }
`

const HeroInfo = styled.div`
  position: absolute;
  left: 0;
  bottom: 2.5rem;
  z-index: 3;

  @media ${maxScreen.smart} {
    display: block;
  }

  @media ${deviceScreen.smland} {
    display: none;
  }

  @media ${deviceScreen.desktop} {
    display: none;
  }
`

const HeroSocial = styled.div`
  position: absolute;
  right: 0;
  bottom: 1.25rem;
  z-index: 3;
`

const Hero = () => {
  const datas = useStaticQuery(
    graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/site/" }) {
          frontmatter {
            hero {
              id
              title
              slug
              description
              images {
                src {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      quality: 100
                      formats: [AUTO, WEBP]
                    )
                  }
                }
                alt
              }
              linkTo
              linkText
            }
          }
        }
      }
    `,
  )

  const { slug } = datas.markdownRemark.frontmatter.hero

  return (
    <Container id={slug}>
      <AnimationFade custom={1} rootMargin='0px 0px' duration='0.2'>
        <HeroBG datas={datas} />

        <Wrapper>
          <HeroContent datas={datas} />
          <HeroInfo>
            <Info direction='flex-start' />
          </HeroInfo>
          <HeroSocial>
            <Social direction='column' />
          </HeroSocial>
        </Wrapper>
      </AnimationFade>
    </Container>
  )
}

export default Hero
